import React, { useEffect, useContext } from 'react';
import Router from 'next/router';
import nookies from 'nookies';

import CartContext from '../contexts/CartContext';
import { logger } from 'adapters/Logger';

const notifySentry = (err, req, cookies) => {
  const url = req?.originalUrl || req?._parsedUrl?.path;

  logger.fatal(
    err,
    {
      query: req?.query,
      params: req?.params,
      url: req?.url || url,
    },
    {
      userEmail: cookies?.email,
      requestUrl: req?.url || url,
      statusCode: req.response?.status,
      requestMethod: req.config?.method,
      type: err?.name,
      userId: cookies?._ga,
    }
  );
};

function Error({ statusCode }) {
  const { rawQuery } = useContext(CartContext);
  useEffect(() => {
    if (rawQuery) Router.replace(`/lead${rawQuery}`);
    else Router.replace(`/lead`);
  }, []);

  return (
    <p>
      {statusCode
        ? `An error ${statusCode} occurred on server`
        : 'An error occurred on client'}
    </p>
  );
}

export async function getServerSideProps(ctx) {
  const cookies = nookies.get(ctx);

  const { res, req, err } = ctx;

  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  if (err) notifySentry(err, req, cookies);

  return { props: { statusCode } };
}

export default Error;
